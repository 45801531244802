import * as React from 'react'
import { IoSendSharp } from 'react-icons/io5'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useCookies } from 'react-cookie'
import { v4 } from 'uuid'

import {
  SEND_MESSAGE,
  START_OVER,
  colors,
  socketIdCookieKey,
  trueLarkLink,
} from '../../constants'
import { useChatContext } from '../Chat'
import { createNewMessage } from '../../utils'

import { useMessageBoxContext } from './MessageBox'
import { useBoundStore } from '../../store'
import { useOutsideClick } from '../../hooks'

const MessageBoxFooter = () => {
  const [value, setValue] = React.useState<string>('')
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false)
  const [cookies] = useCookies([socketIdCookieKey])
  const { sendJsonMessage } = useChatContext()
  const { messages, updateMessages } = useMessageBoxContext()
  const clientId = useBoundStore((state) => state.clientId)
  const brandColor = useBoundStore((state) => state.brandColor)
  const trimValue = value.trim()
  const fullValue = trimValue.replace(/\s/gm, '').toLowerCase()
  const isStartOverText = fullValue.includes('startover')
  const submitButtonRef = React.useRef<HTMLButtonElement>(null)
  const textArea = document.getElementById('message')

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const isEnter = event.code === 'Enter'
    const hasShiftKey = event.shiftKey

    if (isEnter && !hasShiftKey) {
      event.preventDefault()
      submitButtonRef?.current?.click()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setValue(event.target.value)

  const handleToggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const ref = useOutsideClick(() => setTooltipOpen(false))

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (trimValue === '') return setValue('')

    const newMessage = {
      type: SEND_MESSAGE,
      id: v4(),
      timestamp: Date.now(),
      data: { id: v4(), text: isStartOverText ? START_OVER : value },
    }

    const userMessage = createNewMessage({
      id: newMessage.id,
      text: trimValue,
      senderId: cookies.frontdeskaisocketid,
      timestamp: newMessage.timestamp,
      clientId,
    })

    if (!isStartOverText) updateMessages([...messages, userMessage])
    sendJsonMessage(newMessage)
    setValue('')

    if (textArea) {
      textArea.style.height = '42px'
    }
  }

  const handleStartOver = () => {
    setTimeout(() => {
      handleToggleTooltip()
    }, 100)

    const message = {
      type: SEND_MESSAGE,
      id: v4(),
      timestamp: Date.now(),
      data: { id: v4(), text: START_OVER },
    }

    sendJsonMessage(message)
  }

  return (
    <div className="tl tl-flex tl-flex-col tl-gap-2 tl-px-6 tl-py-4 sm:tl-rounded-b-md tl-shadow-sm tl-sticky tl-bottom-0 tl-left-0 tl-bg-white tl-max-h-40">
      <form
        id="user-message"
        className="tl tl-flex tl-items-start tl-gap-1"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <textarea
          is="auto-size"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          class="tl !tl-mr-2 scrollbar-hide tl-max-h-[105px] !tl-resize-none tl-antialiased tl-w-full tl-outline-none !tl-text-black tl-text-sm tl-font-medium placeholder:tl-text-sm placeholder:tl-text-gray-500 !tl-text-gray placeholder:tl-font-medium"
          id="message"
          placeholder="Type your message here"
          onKeyDown={handleKeyDown}
          value={value}
          onChange={handleChange}
        />
        <button
          ref={submitButtonRef}
          className={`!tl-p-0 tl-bottom-[0.4rem] tl-text-sm tl-min-h-[2rem] tl-min-w-[2rem] tl-w-8 tl-h-8 tl-border tl-border-transparent tl-rounded-full tl-relative ${
            trimValue
              ? 'tl-bg-brand tl-cursor-pointer'
              : 'tl-cursor-not-allowed tl-bg-white'
          }`}
          type="submit"
          disabled={!trimValue}
        >
          <div className="tl tl-flex tl-absolute tl-top-[20%] tl-left-1/4">
            <IoSendSharp
              color={trimValue ? colors.white : colors.gray[400]}
              size="18"
            />
          </div>
        </button>
        <button
          onClick={handleToggleTooltip}
          className="tl tl-flex tl-border tl-border-transparent tl-rounded-full"
          type="button"
          ref={ref}
        >
          <BsThreeDotsVertical
            color={tooltipOpen ? brandColor : colors.gray[400]}
            size="18"
          />
        </button>
      </form>
      <div className="tl tl-flex tl-justify-end tl-text-xs tl-font-medium tl-gap-1">
        <span className="tl tl-text-gray-500 tl-antialiased">Powered by</span>
        <span className="tl tl-text-gray-700 tl-antialiased hover:tl-underline">
          <a
            target="_blank"
            href={trueLarkLink}
            rel="noreferrer"
            className="tl tl-antialiased tl-text-xs"
          >
            TrueLark
          </a>
        </span>
      </div>
      {tooltipOpen ? (
        <button
          ref={ref}
          onClick={handleStartOver}
          className="hover:tl-bg-brand hover:tl-text-white tl-font-sans tl-cursor-pointer tl-antialiased tl-text-default tl-border-0 tl-shadow-mid tl-text-gray-700 tl-max-w-fit tl-py-2 tl-px-4 tl-rounded-md tl-absolute tl-right-4 -tl-top-6 tl-bg-white active:tl-bg-brand active:tl-text-white"
        >
          Start Over
        </button>
      ) : null}
    </div>
  )
}

export { MessageBoxFooter }
